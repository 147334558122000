import { useState } from 'react';
import { SearchFilterInput } from '../SearchFilterInput';
import {
  Box,
  Flex,
  Button,
  Collapse,
  Text,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  IconButton,
  MenuDivider,
  MenuGroup,
  Card,
  CardBody,
  Tooltip,
} from '@chakra-ui/react';
import { RowSelectionState } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { defaultEmptyFilters, useSearchArticles } from './useSearchArticles';
import { ArticleSearchProps } from './types';
import {
  emptyPagedItems,
  queryStringToObject,
} from '@texas/utils/helpers/commonHelpers';
import { reactEvents } from 'src/bridge/reactEvents';
import { filters } from '../filter/filters';
import { MoveArticles } from '../shared/MoveArticles';
import { searchApi, VariantsSearchQuery } from '@texas/api/endpoints/searchApi';
import { ModalState } from '@texas/hooks/useChakraOutsideClick';
import { useValueDisclosure } from '@texas/hooks/useValueDisclosure';
import { AddFilter } from '../filter/AddFilter';
import { Icons } from '../shared/Icons';
import { useTexasDrawer, TexasDrawer } from '../shared/drawer/TexasDrawer';
import { TexasDrawerHeader } from '../shared/drawer/TexasDrawerHeader';
import { VerifyButton } from '../shared/verifyButton/VerifyButton';
import {
  defaultPageSize,
  defaultPage,
} from '../shared/dataTable/defaultTableOptions';
import { useExportableArticles } from './useExportableArticles';
import { TexasDrawerContent } from '../shared/drawer/TexasDrawerContent';
import { ChangeState } from '../shared/ChangeState';
import { UpdateVariant } from '@texas/api/endpoints/articlesApi';
import { ChangeCategoryCode } from '../shared/ChangeCategoryCode';
import { TableView } from './TableView';
import { ArticlesCardView } from '../shared/views/ArticlesCardView';
import { BodyOverride } from '../shared/BodyOverride';
import { TexasHeader } from '../shared/TexasHeader';
import { variantsApi } from '@texas/api/endpoints/variantsApi';
import {
  excelExportFileName,
  saveBlobAsFile,
} from '@texas/utils/helpers/filesHelper';

// Texas backend excel generation is too slow to handle too many articles
// until we have reworked the functionality we need to cap the amount
export const maxExportArticlesAmount = 200;

const defaultSearchParams: VariantsSearchQuery = {
  searchTerm: '',
  sortBy: 'itemCode',
  sortDesc: false,
  page: defaultPage,
  ...defaultEmptyFilters,
  showAllVariants: undefined,
  includeArchived: undefined,
  showMyBrands: undefined,
};

const defaultSearchPage: ArticleSearchProps = {
  filters: [],
  searchParams: defaultSearchParams,
  view: 'table',
};

interface RowData {
  articleId: number;
  variantId: number;
  branchId: number;
}

export function SearchArticles() {
  const { t } = useTranslation();

  const {
    articles,
    searchPage,
    searchParamsRef,
    filterElements,
    activeFilters,
    error,
    loading,
    setSearchPage,
    setSearchParamsWithRef,
    handleViewChange,
    setActiveFiltersVariantResults,
    setActiveFilterTypes,
    refetchArticles,
    availableFilters,
    view,
  } = useSearchArticles({
    defaultFilters: defaultEmptyFilters,
    defaultArticleSearchProps: defaultSearchPage,
    defaultPage: defaultPage,
    defaultSearchParams: defaultSearchParams,
    overrideDefaultArticleSearchPage: searchPagePropsFromQueryString(),
    localStorageKey: 'articleSearchPage',
    limit: defaultPageSize,
    request: searchApi.searchVariants,
    defaultData: { pagedItems: emptyPagedItems },
  });

  const [selectedRows, setSelectedRows] = useState<RowSelectionState>({});
  const exportableArticles = useExportableArticles(
    selectedRows,
    articles?.exportArticleItems ?? [],
  );

  const {
    value: moveArticlesValue,
    isOpen: isMoveArticlesOpen,
    onOpen: onMoveArticlesOpen,
    onClose: onMoveArticlesClose,
  } = useValueDisclosure<number[]>();

  const {
    value: changeStateValue,
    isOpen: isChangeStateOpen,
    onOpen: onChangeStateOpen,
    onClose: onChangeStateClose,
  } = useValueDisclosure<UpdateVariant[]>();

  const {
    value: changeCategoryCodeValue,
    isOpen: isChangeCategoryCodeOpen,
    onOpen: onChangeCategoryCodeOpen,
    onClose: onChangeCategoryCodeClose,
  } = useValueDisclosure<UpdateVariant[]>();

  const drawer = useTexasDrawer(ModalState.Closed);
  return (
    <>
      <BodyOverride>
        <TexasDrawer modal={drawer} ref={drawer.ref}>
          <TexasDrawerContent>
            <TexasDrawerHeader title={t('searchArticles.addFilter')} />
            <AddFilter
              availableFilters={availableFilters}
              onAdd={(type) => {
                setActiveFiltersVariantResults((s) => [
                  ...s,
                  {
                    optionType: type,
                    ...defaultEmptyFilters,
                    isPopulated: false,
                  },
                ]);
                setActiveFilterTypes((s) => [...s, [true, type]]);
                setSearchPage((s) => ({
                  ...s,
                  filters: [...s.filters, type],
                }));
              }}
            />
          </TexasDrawerContent>
        </TexasDrawer>
        <MoveArticles
          onArticlesMoved={() => {
            setSelectedRows({});
            refetchArticles();
          }}
          isOpen={isMoveArticlesOpen}
          onClose={onMoveArticlesClose}
          articles={moveArticlesValue}
        />
        <ChangeState
          onStateChanged={() => {
            setSelectedRows({});
            refetchArticles();
          }}
          isOpen={isChangeStateOpen}
          onClose={onChangeStateClose}
          variants={changeStateValue}
        />
        <ChangeCategoryCode
          onCategoryCodeChanged={() => {
            setSelectedRows({});
            refetchArticles();
          }}
          isOpen={isChangeCategoryCodeOpen}
          onClose={onChangeCategoryCodeClose}
          variants={changeCategoryCodeValue}
        />
        <Card variant="dark">
          <CardBody>
            <TexasHeader text={t('searchArticles.searchArticles')} />
            <Flex direction="column" alignItems="start" px={4}>
              <Box mt={2} mb={4} width="100%">
                <SearchFilterInput
                  placeholder={t('searchArticles.itemcodeOrDescription')}
                  value={searchPage.searchParams.searchTerm}
                  onChange={(searchTerm) =>
                    setSearchParamsWithRef({
                      ...searchParamsRef.current,
                      searchTerm,
                      page: defaultPage,
                    })
                  }
                />
              </Box>
              <Flex w="100%" mt="0 !important" justifyContent="space-between">
                <Collapse in={activeFilters.length > 0}>
                  <VerifyButton
                    buttonProps={{
                      variant: 'texas-outline-light',
                      size: 'sm',
                    }}
                    onVerified={() => {
                      setActiveFilterTypes([]);
                      setActiveFiltersVariantResults((f) =>
                        f.filter((f2) => f2.optionType !== undefined),
                      );
                      setSearchParamsWithRef(defaultSearchPage.searchParams);
                      setSearchPage((s) => ({ ...s, filters: [] }));
                    }}
                    label={t('searchArticles.clearAllFilters')}
                  />
                </Collapse>
                <Flex ml="auto" gap={2}>
                  <Tooltip label={t('view.cardView')}>
                    <IconButton
                      variant={view === 'card' ? 'texas-solid' : 'texas-light'}
                      size="sm"
                      icon={<Icons.viewCard />}
                      aria-label={t('view.cardView')}
                      onClick={() => handleViewChange('card')}
                    />
                  </Tooltip>
                  <Tooltip label={t('view.tableView')}>
                    <IconButton
                      variant={view === 'table' ? 'texas-solid' : 'texas-light'}
                      size="sm"
                      icon={<Icons.viewTable />}
                      aria-label={t('view.tableView')}
                      onClick={() => handleViewChange('table')}
                    />
                  </Tooltip>
                  <Button
                    variant="texas-light"
                    size="sm"
                    onClick={() => drawer.setModalState(ModalState.Open)}
                  >
                    {t('searchArticles.addFilter')}
                  </Button>
                </Flex>
              </Flex>
              <Box
                width="100%"
                display="grid"
                gridTemplateColumns="repeat(6, 1fr)"
                gridGap={2}
                rowGap={4}
              >
                {filterElements}
              </Box>
              {!loading && (
                <Text variant="sub" pt={2}>
                  {t('searchArticles.foundArticles', {
                    count: articles?.pagedItems.totalItems,
                  })}
                </Text>
              )}
            </Flex>
            <Box hidden={view !== 'table'}>
              <Flex
                backgroundColor="gray.600"
                _light={{ backgroundColor: 'gray.50' }}
                mt={4}
                py={2}
                px={4}
                alignItems="center"
                justify="space-between"
              >
                <Text>{t('general.articles')}</Text>
                <Menu>
                  <MenuButton
                    as={IconButton}
                    size="sm"
                    icon={<Icons.DotsHorizontal />}
                    aria-label="Options"
                    variant="texas-light"
                  />
                  <MenuList>
                    <MenuItem
                      onClick={async () => {
                        const response = await variantsApi.downloadExcel(
                          articles?.exportArticleItems.flatMap((x) => {
                            return x.branches.flatMap((b) => ({
                              variantId: x.variantId,
                              branchId: b.id,
                            }));
                          }) ?? [],
                        );

                        if (response.hasError) return;

                        saveBlobAsFile(response.data, excelExportFileName());
                      }}
                      isDisabled={articles?.pagedItems.totalItems == 0}
                      icon={<Icons.FileExport boxSize={5} />}
                    >
                      {t('general.exportExcel')}
                    </MenuItem>
                    <MenuItem
                      isDisabled={
                        Object.keys(selectedRows).length === 0 ||
                        Object.keys(selectedRows).length >
                          maxExportArticlesAmount
                      }
                      onClick={() => {
                        reactEvents.excelExportClicked.dispatch({
                          articles: exportableArticles,
                          branchIds: searchPage.searchParams['branchIds'] ?? [],
                          hideBranchIds: true,
                        });
                      }}
                    >
                      {t('general.exportExcelOld')}
                    </MenuItem>
                    <MenuDivider />
                    <MenuGroup title={t('general.actions')}>
                      <MenuItem
                        isDisabled={Object.keys(selectedRows).length === 0}
                        onClick={() => {
                          onMoveArticlesOpen(
                            Object.keys(selectedRows).map((x) => {
                              const data: RowData = JSON.parse(x);
                              return data.articleId;
                            }),
                          );
                        }}
                      >
                        {t('article.moveArticles')}
                      </MenuItem>
                      <MenuItem
                        isDisabled={Object.keys(selectedRows).length === 0}
                        onClick={() => {
                          onChangeStateOpen(
                            Object.keys(selectedRows).map((x) => {
                              const data: RowData = JSON.parse(x);
                              const updateVariant: UpdateVariant = {
                                variantId: data.variantId,
                                branchId: data.branchId,
                              };
                              return updateVariant;
                            }),
                          );
                        }}
                      >
                        {t('article.changeState')}
                      </MenuItem>
                      <MenuItem
                        isDisabled={Object.keys(selectedRows).length === 0}
                        onClick={() => {
                          onChangeCategoryCodeOpen(
                            Object.keys(selectedRows).map((x) => {
                              const data: RowData = JSON.parse(x);
                              const updateVariant: UpdateVariant = {
                                variantId: data.variantId,
                                branchId: data.branchId,
                              };
                              return updateVariant;
                            }),
                          );
                        }}
                      >
                        {t('article.changeCategoryCode')}
                      </MenuItem>
                    </MenuGroup>
                  </MenuList>
                </Menu>
              </Flex>
              <TableView
                articles={articles}
                searchPage={searchPage}
                searchParamsRef={searchParamsRef}
                setSearchParamsWithRef={setSearchParamsWithRef}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                error={error}
                loading={loading}
              />
            </Box>
            <Box hidden={view !== 'card'}>
              <ArticlesCardView
                articles={articles}
                searchPage={searchPage}
                searchParamsRef={searchParamsRef}
                setSearchParamsWithRef={setSearchParamsWithRef}
                error={error}
                loading={loading}
              />
            </Box>
          </CardBody>
        </Card>
      </BodyOverride>
    </>
  );

  function searchPagePropsFromQueryString(): ArticleSearchProps | undefined {
    const searchQueryParams: VariantsSearchQuery | undefined =
      queryStringToObject();

    if (!searchQueryParams) return;

    const mappedFilters = filters
      .filter((f) => searchQueryParams[f.queryParamsKey] !== undefined)
      .map((f) => f.optionType);

    return {
      filters: mappedFilters,
      searchParams: searchQueryParams,
      view: 'table',
    };
  }
}
