import { FilterOptionIdsQuery } from './api/endpoints/productApprovalsApi';
import { VariantsSearchQueryRequest } from './api/endpoints/searchApi';
import { objectToQueryString } from './utils/helpers/commonHelpers';

export const clientEndpoints = {
  articleProductApproval: (articleId: number, query?: FilterOptionIdsQuery) =>
    `#app/articles/${articleId}/product-approval${objectToQueryString(query)}`,
  previewImage: (identifier: string, size: number) =>
    `/api/files/${identifier}/preview/${size}`,
  previewPdf: (identifier: string) => `/api/files/${identifier}/preview/pdf`,
  previewArticleArtworkImage: (variantId: number, fileId: number) =>
    `/api/variants/${variantId}/artworkimage?id=${fileId}`,
  previewArticlePhoto: (variantId: number, fileId: number) =>
    `/api/variants/${variantId}/photo?id=${fileId}`,
  downloadFile: (identifier: string) => `/api/files/${identifier}`,
  downloadArticlesExport: (query: VariantsSearchQueryRequest) => {
    const branchIds =
      query.branchIds && query.branchIds.length > 0
        ? `&branchIds=${query.branchIds.join('&branchIds=')}`
        : '';

    const states =
      query.states && query.states.length > 0
        ? `&states=${query.states.join('&states=')}`
        : '';

    const customerIds =
      query.customerIds && query.customerIds.length > 0
        ? `&customerIds=${query.customerIds.join('&customerIds=')}`
        : '';

    const categoryCodeIds =
      query.categoryCodeIds && query.categoryCodeIds.length > 0
        ? `&categoryCodeIds=${query.categoryCodeIds.join('&categoryCodeIds=')}`
        : '';

    const conceptIds =
      query.conceptIds && query.conceptIds.length > 0
        ? `&conceptIds=${query.conceptIds.join('&conceptIds=')}`
        : '';

    const materialIds =
      query.materialIds && query.materialIds.length > 0
        ? `&materialIds=${query.materialIds.join('&materialIds=')}`
        : '';

    const productGroupIds =
      query.productGroupIds && query.productGroupIds.length > 0
        ? `&productGroupIds=${query.productGroupIds.join('&productGroupIds=')}`
        : '';

    const processes =
      query.processes && query.processes.length > 0
        ? `&processes=${query.processes.join('&processes=')}`
        : '';

    const queryString = `?searchTerm=${query.searchTerm}
		&sortBy=${query.sortBy}
		&sortDesc=${query.sortDesc}
		&page=1
		&limit=0
		${branchIds}${states}${customerIds}${categoryCodeIds}
		${conceptIds}${materialIds}${productGroupIds}${processes}
		&showMyBrands=${query.showMyBrands ?? false}
		&includeArchived=${query.includeArchived ?? false}
		&optOutDefaultOrdering=${query.optOutDefaultOrdering}`;

    return `/api/excel/articles/export${queryString}`;
  },
};
