import { Box } from '@chakra-ui/react';
import checker from '@assets/checker.svg';

export function ColorDot({ hex }: { hex: string | null }) {
  return (
    <Box
      boxSize="4"
      borderRadius="full"
      border="1px solid gray"
      {...((hex?.length ?? 0) > 0
        ? { bgColor: `#${hex}` }
        : {
            bg: `url(${checker})`,
            backgroundSize: 'contain',
            opacity: 0.2,
            backgroundColor: '#b6bde5',
          })}
    />
  );
}
