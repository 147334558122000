import { DropdownIndicatorProps, StylesConfig, components } from 'react-select';
import {
  SelectFilterOption,
  ToggleFilterOption,
  GroupedOption,
} from '../ArticlesList/filterGroupedOptions';
import { CSSProperties } from 'react';
import { defaultIconSize, Icons } from '@texas/components/shared/Icons';
import { Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};
export const groupBadgeStyles: CSSProperties = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.17em 0.5em',
  textAlign: 'center',
};

export const articlesWidgetSelectStyles: StylesConfig<
  SelectFilterOption | ToggleFilterOption,
  false,
  GroupedOption
> = {
  container: (baseStyles, _) => ({
    ...baseStyles,
  }),
  control: (baseStyles, _) => ({
    ...baseStyles,
    border: 'none',
    minHeight: 0,
    background: 'transparent',
  }),
  indicatorSeparator: (baseStyles, _) => ({
    ...baseStyles,
    display: 'none',
  }),
  valueContainer: (baseStyles, _) => ({
    ...baseStyles,
    width: 0,
    height: 0,
    visibility: 'hidden',
    padding: 0,
  }),
  indicatorsContainer: (baseStyles, _) => ({
    ...baseStyles,
    alignItems: 'flex-start',
  }),
  dropdownIndicator: (baseStyles, _) => ({
    ...baseStyles,
    padding: 0,
  }),
  option: (baseStyles, { isFocused }) => ({
    ...baseStyles,
    backgroundColor: isFocused
      ? 'rgb(240, 240, 240)'
      : 'transparent !important',
    cursor: 'pointer',
    paddingTop: 2,
    paddingBottom: 2,
    color: 'black',
    fontSize: '0.8em',
  }),
  placeholder: (baseStyles, _) => ({
    ...baseStyles,
    color: 'white',
  }),
  input: (baseStyles, _) => ({
    ...baseStyles,
    color: 'white',
  }),
  menu: (baseStyles, _) => ({
    ...baseStyles,
    minWidth: '160px',
    zIndex: 9999,
  }),
};

export const ArticlesDropdownIndicator = (
  props: DropdownIndicatorProps<
    SelectFilterOption | ToggleFilterOption,
    false,
    GroupedOption
  >,
) => {
  const { t } = useTranslation();
  return (
    <components.DropdownIndicator {...props}>
      <Flex
        alignItems="center"
        gap={1}
        padding={2}
        cursor="pointer"
        color="white"
        bg="texas.bg.700"
        _hover={{ color: 'gray.50' }}
        _light={{ color: 'black', bg: 'gray.100' }}
        borderRadius="40px"
      >
        <Icons.Plus boxSize={defaultIconSize} />
        <Text>{t('searchArticles.addFilter')}</Text>
      </Flex>
    </components.DropdownIndicator>
  );
};
