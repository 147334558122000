import { Box, IconButton, Tooltip } from '@chakra-ui/react';
import { Icons } from './Icons';
import { useTranslation } from 'react-i18next';

export function RemoveComponent({
  onClick,
  children,
}: {
  onClick?: () => void;
  children: React.ReactNode;
}) {
  const { t } = useTranslation();

  if (!onClick) return <>{children}</>;

  return (
    <Box pos="relative" role="group">
      {children}
      <Box
        pos="absolute"
        right={-2}
        top={-3}
        transition="transform 200ms ease, opacity 200ms ease"
        opacity={0}
        transform="scale(0.9)"
        _groupHover={{ opacity: 1, transform: 'scale(1)' }}
      >
        <Tooltip label={t('general.clickToRemove')}>
          <IconButton
            colorScheme="red"
            variant="solid"
            size="sm"
            onClick={onClick}
            aria-label="remove"
            icon={<Icons.Close />}
          />
        </Tooltip>
      </Box>
    </Box>
  );
}
