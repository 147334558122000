import { PagedItems } from '@texas/types';
import { httpClient } from '../httpClient/httpClient';
import { ArticleState } from './searchApi';

export interface RecommendedSuppliers {
  productGroup: string | null;
  state: ArticleState;
  suppliers: SupplierListItem[];
}

export interface SupplierListItem {
  id: number;
  name: string;
  archived: string | null;
  activeArticles: number;
  alreadyAdded: boolean;
}

export interface AddSupplierRequest {
  name: string;
  organizationId: number;
}

interface Supplier {
  id: number;
  name: string;
  dmixIdentifier: string;
  email: string;
  organizationId: number;
  folderId: number;
  documentsFolderId: number;
  emailsFolderId: number;
  samplesFolderId: number;
  archived: string;
  branchData: SupplierBranchData[];
}

interface SupplierBranchData {
  supplierId: number;
  branchId: number;
}

export interface SupplierSearchQuery {
  organizationId: number;
  searchTerm: string;
  includeArchived: boolean;
  sortDesc: boolean;
  page: number;
  pageSize: number;
}

export interface ContactsSearchQuery {
  searchTerm: string;
  includeArchived: boolean;
  sortBy: string;
  sortDesc: boolean;
}

export interface SupplierContact {
  id: number;
  name: string;
  email: string;
  archived?: string | null;
}

export const suppliersApi = {
  searchSuppliers: (params: SupplierSearchQuery) =>
    httpClient.get<PagedItems<SupplierListItem>>('suppliers/search', {
      params,
    }),
  createSupplier: (data: AddSupplierRequest) =>
    httpClient.post<Supplier>(`suppliers`, { data }),
  getContacts: (supplierId: number, params: ContactsSearchQuery) =>
    httpClient.get<SupplierContact[]>(`${supplierId}/contacts`, { params }),
  variant: {
    getRecommendedSuppliers: (variantId: number, branchId: number) =>
      httpClient.get<RecommendedSuppliers>(
        `suppliers/variant/${variantId}/recommended/${branchId}`,
      ),
    getAllBranchSuppliers: (variantId: number, branchId: number) =>
      httpClient.get<SupplierListItem[]>(
        `suppliers/variant/${variantId}/all/${branchId}`,
      ),
  },
};
