import { ArticleNodeGroup } from '@texas/api/endpoints/articlesApi';
import { CompositionGroup } from '@texas/api/endpoints/compositionGroupApi';
import { SimpleContact } from '@texas/api/endpoints/contactsApi';

export interface ProductApprovalBranch {
  id: number;
  name: string;
  identifier: string;
}

export interface ProductApprovalCell {
  x: number;
  xIndex: number;
  y: number;
  yIndex: number;
  added: boolean;
}

export interface ProductApprovalSupplier {
  id: number;
  name: string;
  branch: ProductApprovalBranch;
  defaultContacts: SimpleContact[] | null;
}

export enum GeneratedProductApprovalState {
  Unhandled = 0,
  Accepted = 1,
  Ignored = 2,
}

export interface GeneratedProductApproval {
  key: string;
  customerContact: SimpleContact | null;
  supplierContact: SimpleContact | null;
  supplier: ProductApprovalSupplier;
  cell: ProductApprovalCell | null;
  branch: ProductApprovalBranch;
  supplierNote: string;
  supplierExternalItemCode: string;
  issuedBy: SimpleContact;
  state: GeneratedProductApprovalState;
  compositionGroup: CompositionGroup | null;
}

export interface ProductApprovalStateData {
  variantId: number;
  customerId: number;
  articleNodes: ArticleNodeGroup | undefined;
  selectedBranches: ProductApprovalBranch[];
  selectedCells: Record<string, ProductApprovalCell>;
  selectedSuppliers: ProductApprovalSupplier[];
  generatedProductApprovals: Record<string, GeneratedProductApproval>;
  generatedProductApprovalKeys: string[];
}
