import {
  Flex,
  Box,
  Text,
  Editable,
  EditableInput,
  EditablePreview,
  Input,
  FormLabel,
  Tooltip,
} from '@chakra-ui/react';
import { fadeInScaleAnimation } from '@texas/resources/animations/animations';
import { Color } from './ColorPicker';
import { RemoveComponent } from '../RemoveComponent';
import checker from '@assets/checker.svg';
import { defaultIconSize, Icons } from '../Icons';
import { EditableControls } from '../EditableControls';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export function ColorBox({
  color,
  colorDescription,
  onRemove,
  onEdit,
  onColorChange,
  fallbackDefaultValueHex,
}: {
  color: Color;
  colorDescription?: string;
  onRemove?: () => void;
  onEdit?: (description: string) => void;
  onColorChange?: (hex: string) => void;
  fallbackDefaultValueHex?: string;
}) {
  const [hex, setHex] = useState(color.hex);
  const { t } = useTranslation();

  useEffect(() => {
    setHex(color.hex);
  }, [color.hex]);

  return (
    <RemoveComponent onClick={onRemove}>
      <Box
        animation={fadeInScaleAnimation()}
        borderRadius="md"
        overflow="hidden"
        border="1px solid gray"
        minH="60px"
      >
        {(hex?.length ?? 0) > 0 ? (
          <Box h={8} bgColor={`#${hex}`}>
            {onColorChange && (
              <ChangeColor
                onBlur={onColorChange}
                onChange={(h) => setHex(h)}
                defaultValue={`#${hex ?? fallbackDefaultValueHex}`}
              />
            )}
          </Box>
        ) : (
          <Box h={8} bg={`url(${checker})`} backgroundSize="contain">
            {onColorChange && (
              <ChangeColor
                onBlur={onColorChange}
                onChange={(h) => setHex(h)}
                defaultValue={`#${fallbackDefaultValueHex}`}
              />
            )}
          </Box>
        )}
        <Flex
          bg="black"
          _light={{ bg: 'white' }}
          px={2}
          py={1}
          flexDir="column"
          color="white"
          flexGrow={1}
        >
          <Flex gap={2} alignItems="baseline">
            <Text variant="main"> {color.name}</Text>
            <Text variant="small">{color.code}</Text>
          </Flex>
          {onEdit ? (
            <>
              <Editable
                as={Flex}
                gap={1}
                placeholder={t('colors.addDescription')}
                defaultValue={colorDescription}
              >
                <EditablePreview color="gray.400" />
                <EditableInput
                  maxLength={30}
                  onChange={(e) => onEdit(e.target.value)}
                />
                <EditableControls />
              </Editable>
            </>
          ) : (
            <Text fontSize="sm" variant="main">
              {colorDescription}
            </Text>
          )}
        </Flex>
      </Box>
    </RemoveComponent>
  );
}

function ChangeColor({
  onChange,
  onBlur,
  defaultValue,
}: {
  onChange: (hex: string) => void;
  onBlur: (hex: string) => void;
  defaultValue?: string;
}) {
  const { t } = useTranslation();
  return (
    <Tooltip label={t('colors.changeColor')}>
      <FormLabel m={0} boxSize="full" pos="relative" cursor="pointer">
        <Flex h="full" justify="center" align="center">
          <Box
            p={1}
            borderRadius="full"
            bg="whiteAlpha.800"
            _groupHover={{ opacity: 1, transform: 'scale(1)' }}
            opacity={0}
            transform="scale(0.3)"
            transition="transform 200ms ease, opacity 200ms ease"
          >
            <Icons.Pencil color="black" boxSize={defaultIconSize} />
          </Box>
        </Flex>
        <Input
          defaultValue={defaultValue}
          pos="absolute"
          bottom={1}
          right={1}
          boxSize={0}
          margin={0}
          border={0}
          padding={0}
          onBlur={(e) => {
            onBlur(e.target.value.slice(1, e.target.value.length));
          }}
          onChange={(e) =>
            onChange(e.target.value.slice(1, e.target.value.length))
          }
          type="color"
        />
      </FormLabel>
    </Tooltip>
  );
}
