import {
  Flex,
  Box,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Tag,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import {
  Composition,
  compositionApi,
  CompositionData,
  CompositionMaterial,
} from '@texas/api/endpoints/compositionApi';
import { fadeInLeftAnimation } from '@texas/resources/animations/animations';
import { useTranslation } from 'react-i18next';
import { ColorDot } from '../../../../../shared/colorPicker/ColorDot';
import { ColorBox } from '../../../../../shared/colorPicker/ColorBox';
import useFormat from '@texas/hooks/useFormat';
import { Icons } from '@texas/components/shared/Icons';
import { formatDate } from '@texas/utils/helpers/dateHelper';
import { Locale } from '@texas/i18n/types';
import { Character, getCharacterLabel } from '../../../general/types';
import { convertToEnum } from '@texas/utils/helpers/enumHelpers';
import { clientEndpoints } from '@texas/clientEndpoints';
import { ImageDropzone } from '@texas/components/shared/dropzone/ImageDropzone';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { useCallback, useEffect } from 'react';
import { FileLink, filesApi } from '@texas/api/endpoints/filesApi';
import { request } from '@texas/utils/helpers/httpHelpers';
import { ServerError } from '@texas/types';
import { ErrorsList } from '@texas/components/shared/ErrorsList';
import { useFileUploads } from '@texas/api/hooks/useFileUploads';
import {
  acceptedImageFormats,
  imageExtensions,
} from '@texas/utils/helpers/filesHelper';
import { VerifyDialogWithRequest } from '@texas/components/shared/dialog/VerifyDialogWithRequest';
import { FileBrowserModal } from '@texas/components/shared/files/FileBrowserModal';
import { useApiResource } from '@texas/api/hooks/useApiResource';
import { ErrorDetails } from '@texas/components/shared/alert/ErrorDetails';
import { maxSize } from '@texas/components/shared/dropzone/shared';
import { compositionGroupEvents } from '../events';
import { FileDropzone } from '@texas/components/shared/dropzone/FileDropzone';
import { GroupContext } from '../group/CompositionGroupView';
import { useActiveContext } from '@texas/hooks/useActiveContext';
import { GetColorWithType } from '@texas/components/shared/colorPicker/ColorPicker';

export function CompositionView({
  composition,
  archiveDisabled,
}: {
  composition: Composition;
  archiveDisabled: boolean;
  onRestore?: (data: Composition) => void;
}) {
  const { t } = useTranslation();
  const toast = useToast();
  const { formatWeight } = useFormat();
  const { group, componentSettings, anyProductApproval } =
    useActiveContext(GroupContext);

  const {
    isOpen: isFileBrowserOpen,
    onClose: onFileBrowserClose,
    onOpen: onFileBrowserOpen,
  } = useDisclosure();

  const {
    isOpen: isRemoveFileOpen,
    onClose: onRemoveFileClose,
    onOpen: onRemoveFileOpen,
  } = useDisclosure();

  const {
    data: file,
    set: setFile,
    loading: loadingFile,
    refetch,
    error,
  } = useApiResource(filesApi.getFileLink);

  useEffect(() => {
    if (composition.fileId) refetch(composition.fileId);
    else setFile(null);
  }, [composition.fileId, refetch, setFile]);

  const { request: updateRequest } = useApiRequest(compositionApi.updateFile);

  const updateFile = useCallback(
    (file: FileLink) => {
      request(
        updateRequest,
        [composition.id, file.id],
        (_) => {
          toast({
            title: t('fileBrowser.updatedFile'),
            status: 'success',
            isClosable: true,
          });
          setFile(file);
          compositionGroupEvents.compositionFileUpdated.dispatch();
        },
        (error: ServerError) => {
          toast({
            title: t('fileBrowser.updateFileFailed'),
            description: <ErrorsList errors={error.errors} />,
            status: 'error',
            isClosable: true,
          });
        },
      );
    },
    [composition.id, setFile, t, toast, updateRequest],
  );

  const {
    fileUploads,
    isUploading,
    uploadFailed,
    uploadFiles,
    abortFileUpload,
  } = useFileUploads(updateFile, maxSize);

  return (
    <Flex flexDir="column" gap={2} animation={fadeInLeftAnimation()}>
      {composition.archived && !archiveDisabled && (
        <Flex justify="center" gap={2} mt={2}>
          <Tag
            color="texas.sand.100"
            _light={{ color: 'texas.burntSienna.500' }}
          >
            <Icons.AlertCircle mr={1} />
            {`${t('general.archived')} ${formatDate(
              Locale.En,
              composition.archived,
            )}`}
          </Tag>
        </Flex>
      )}

      {error && <ErrorDetails error={error} />}
      {file && (
        <ImageDropzone
          imageId={file.id}
          imageIdentifier={file.identifier}
          imageSrc={clientEndpoints.previewImage(file.identifier, 312)}
          imageArchived={file.archived}
          allowEdit={
            componentSettings?.compositionEdit === 'hidden' ||
            anyProductApproval
              ? false
              : true
          }
          onUpload={(files) => uploadFiles(files, composition.folderId)}
          isUploading={isUploading}
          uploadFailed={uploadFailed}
          fileUploads={fileUploads}
          abortFileUpload={abortFileUpload}
          onRemovefileOpen={onRemoveFileOpen}
          onFileBrowserOpen={onFileBrowserOpen}
          webcamPhotoPrefix="composition"
        />
      )}
      {!loadingFile && !file && !composition.archived && !group?.archived && (
        <Box m={2}>
          <FileDropzone
            accept={acceptedImageFormats}
            isUploading={isUploading}
            uploadFailed={uploadFailed}
            fileUploads={fileUploads}
            abortFileUpload={abortFileUpload}
            onUpload={(files) => uploadFiles(files, composition.folderId)}
            onFileBrowserOpen={onFileBrowserOpen}
            webcamPhotoPrefix="composition"
          />
        </Box>
      )}
      {composition.note && <Text textAlign="center">{composition.note}</Text>}
      <Flex alignSelf="center" gap={2} flexWrap="wrap" justifyContent="center">
        {!composition.dimension && (
          <NoData label={t('composition.noDimension')} />
        )}
        {composition.dimension?.dimensions.map((x) => (
          <Tag key={x.id}>
            {x.label}: {x.value}mm
          </Tag>
        ))}
        {convertToEnum(Character, composition.character) !== Character.None && (
          <Tag>{getCharacterLabel(composition.character)}</Tag>
        )}
      </Flex>
      <Box px={4}>
        <Header label={t('general.materials')} />
        <Text fontSize="sm">
          {t('composition.weightAmount', {
            amount: formatWeight(composition.weight),
          })}
        </Text>
      </Box>
      <Accordion
        allowMultiple={true}
        defaultIndex={composition.materials.map((x, i) => i)}
      >
        {composition.materials.map((x) => (
          <MaterialComposition key={x.id} compositionMaterial={x} />
        ))}
      </Accordion>
      <FileBrowserModal
        rootFolderId={composition.folderId}
        mode="Select"
        isOpen={isFileBrowserOpen}
        onClose={onFileBrowserClose}
        extensionFilter={imageExtensions}
        onSelect={(file: FileLink) => {
          updateFile(file);
          onFileBrowserClose();
        }}
      />
      <VerifyDialogWithRequest
        headerTitle={t('fileBrowser.removeImage')}
        secondaryButtonTitle={t('general.cancel')}
        primaryButtonTitle={t('general.confirm')}
        primaryRequest={compositionApi.deleteFile}
        args={[composition.id]}
        isOpen={isRemoveFileOpen}
        onClose={onRemoveFileClose}
        onPerformed={() => {
          setFile(null);
          compositionGroupEvents.compositionFileUpdated.dispatch();
        }}
        onSuccessTitle={t('fileBrowser.fileRemoved')}
      >
        {t('alert.areYouSure')}
      </VerifyDialogWithRequest>
    </Flex>
  );
}

function MaterialComposition({
  compositionMaterial,
}: {
  compositionMaterial: CompositionMaterial;
}) {
  const { t } = useTranslation();
  return (
    <AccordionItem>
      <AccordionButton
        gap={2}
        bg="whiteAlpha.50"
        textAlign="start"
        _hover={{ bg: 'whiteAlpha.100' }}
        _light={{ bg: 'blackAlpha.50', _hover: { bg: 'blackAlpha.100' } }}
      >
        <Flex flexDir="column">
          <Text fontWeight="bold">{compositionMaterial.material.text}</Text>
          <Text
            overflowWrap="anywhere"
            noOfLines={3}
            fontSize="sm"
            color="gray.300"
          >
            {compositionMaterial.note}
          </Text>
        </Flex>

        <Flex
          gap={2}
          ml="auto"
          minW="32px"
          flexWrap="wrap"
          justifyContent="end"
        >
          {compositionMaterial.colors.map((x) => {
            const color = x.colorType == null ? GetColorWithType(x) : x;

            return <ColorDot key={color.name} hex={color.hex} />;
          })}
        </Flex>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel>
        <CompositionDataView
          label={t('configuration.qualities')}
          data={compositionMaterial.qualities}
        />
        <CompositionDataView
          label={t('general.techniques')}
          data={compositionMaterial.techniques}
        />
        <CompositionDataView
          label={t('configuration.treatments')}
          data={compositionMaterial.treatments}
        />
        <Header label={t('general.colors')} />
        <Flex flexWrap="wrap" gap={2}>
          {compositionMaterial.colors.length === 0 && <NoData />}
          {compositionMaterial.colors.map((x) => {
            const color = x.colorType ? x : GetColorWithType(x);

            return (
              <ColorBox
                key={color.code}
                colorDescription={x.description}
                color={{
                  name: color.name,
                  code: color.code,
                  hex: color.hex,
                }}
              />
            );
          })}
        </Flex>
      </AccordionPanel>
    </AccordionItem>
  );
}

function CompositionDataView({
  data,
  label,
}: {
  data: CompositionData[];
  label: string;
}) {
  return (
    <Box>
      <Header label={label} />
      {data.length === 0 && <NoData />}
      {data.map((x) => (
        <Flex fontSize="sm" key={x.id} alignItems="baseline">
          <Icons.CircleMedium pt="0.5" />
          <Text>{x.text}</Text>
          {x.value && <Text fontWeight="bold">: {x.value}</Text>}
        </Flex>
      ))}
    </Box>
  );
}

function Header({ label }: { label: string }) {
  return (
    <Text w="100%" variant="main" pt={1} pb="0.5">
      {label}
    </Text>
  );
}

function NoData({ label }: { label?: string }) {
  const { t } = useTranslation();

  return <Text color="gray.400">{label ? label : t('general.noData')}</Text>;
}
