function parseEnum(_enum: any) {
  const allValues: (string | number)[] = Object.values(_enum);
  const properties: string[] = allValues.slice(
    0,
    allValues.length / 2,
  ) as string[];
  const values: (string | number)[] = allValues.slice(allValues.length / 2);
  const enumObj: Record<string, number | string> = {};

  properties.forEach((prop, index) => {
    enumObj[prop] = values[index];
  });

  return enumObj;
}

function getEnumNamesAndValues<T extends number | string>(_enum: any) {
  const obj = parseEnum(_enum);
  return Object.keys(obj).map((n) => ({ name: n, value: obj[n] as T }));
}

function getEnumNames(_enum: any) {
  return Object.keys(parseEnum(_enum));
}

function getEnumValues<T extends number | string>(_enum: any): T[] {
  return Object.values(parseEnum(_enum)) as T[];
}

function convertToEnum<T>(enumType: T, value: any) {
  return enumType[value as unknown as keyof typeof enumType];
}

function ensureEnumNumber<T>(_enum: any, value: T): T {
  if (typeof value === 'string') {
    return convertToEnum(_enum, value);
  }

  return value;
}

export {
  parseEnum,
  getEnumNamesAndValues,
  getEnumNames,
  getEnumValues,
  convertToEnum,
  ensureEnumNumber,
};
