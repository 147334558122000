import {
  Flex,
  Button,
  Text,
  Tooltip,
  useToast,
  useColorMode,
} from '@chakra-ui/react';
import { ArticleNodeGroup } from '@texas/api/endpoints/articlesApi';
import { useCallback } from 'react';
import { MatrixGrid } from '../../../../shared/matrix/MatrixGrid';
import { onlyUnique } from '@texas/utils/helpers/commonHelpers';
import {
  CompositionCell,
  compositionGroupApi,
} from '@texas/api/endpoints/compositionGroupApi';
import { useTranslation } from 'react-i18next';
import { useCustomDroppable } from '@texas/components/shared/dragAndDrop/useCustomDroppable';
import { droppableZoneStyles } from '@texas/components/shared/dragAndDrop/droppableZoneStyles';
import { request } from '@texas/utils/helpers/httpHelpers';
import { compositionGroupEvents } from '../shared/events';
import { ServerError } from '@texas/types';
import { defaultIconSize, Icons } from '@texas/components/shared/Icons';
import { fadeInScaleAnimation } from '@texas/resources/animations/animations';
import { ColorDot } from '@texas/components/shared/colorPicker/ColorDot';
import { GetColorWithType } from '@texas/components/shared/colorPicker/ColorPicker';

type Cell = [number, number, number, number];

export function MatrixView({
  cells,
  cellsLoading,
  articleNodeGroup,
  onCellSelected,
  selectedCell,
  variantId,
}: {
  cells: CompositionCell[] | null;
  refetchCells: () => void;
  cellsLoading: boolean;
  articleNodeGroup: ArticleNodeGroup;
  onCellSelected: (cell: Cell) => void;
  selectedCell: Cell | null;
  variantId: number;
}) {
  const getColors = useCallback(
    (y: number) => {
      const nodes = cells?.filter((x) => x.y === y);
      return nodes?.flatMap((x) => x.colors).filter(onlyUnique);
    },
    [cells],
  );
  const { t } = useTranslation();
  const toast = useToast();

  const copyRequest = useCallback(
    async (fromId: number, cellX: number, cellY: number) => {
      await request(
        compositionGroupApi.copyGroup,
        [
          fromId,
          {
            cellX,
            cellY,
            replaceCompositionGroupId: undefined,
            variantId: variantId,
          },
        ],
        (_) => {
          compositionGroupEvents.compositionCellOptionCopied.dispatch({
            cellX,
            cellY,
          });
          compositionGroupEvents.compositionGroupsChanged.dispatch();
        },
        (error: ServerError) => {
          toast({
            title: t('general.copyFailed'),
            description: error.message,
            status: 'error',
            isClosable: true,
          });
        },
      );
    },
    [t, toast, variantId],
  );

  return (
    <Flex>
      <MatrixGrid
        articleNodeGroup={articleNodeGroup}
        row={(y, _) => {
          const colors = getColors(y);
          return (
            <Flex gap={2}>
              {colors?.map((c) => {
                const color = c.colorType == null ? GetColorWithType(c) : c;

                return <ColorDot key={color.code} hex={color.hex} />;
              })}
            </Flex>
          );
        }}
        cell={(x, y, xIndex, yIndex) => {
          const cell = cells?.find((c) => c.x === x && c.y === y);

          return (
            <Cell
              x={x}
              y={y}
              cell={cell}
              cellsLoading={cellsLoading}
              selectedCell={selectedCell}
              onCellSelected={() => onCellSelected([x, y, xIndex, yIndex])}
              onOptionDrop={(droppedOptionId) =>
                copyRequest(droppedOptionId, x, y)
              }
            />
          );
        }}
      />
    </Flex>
  );
}

function Cell({
  x,
  y,
  cell,
  cellsLoading,
  selectedCell,
  onCellSelected,
  onOptionDrop,
}: {
  x: number;
  y: number;
  cell: CompositionCell | undefined;
  cellsLoading: boolean;
  selectedCell: Cell | null;
  onCellSelected: () => void;
  onOptionDrop: (droppedOptionId: number) => void;
}) {
  const { t } = useTranslation();
  const { setNodeRef, isOver, validDropZone, isDragging } = useCustomDroppable({
    id: `${x}-${y}`,
    data: {
      location: cell,
    },
    condition: (_) => {
      return true;
    },
    onDrop: (data) => {
      onOptionDrop(data.optionId);
    },
  });
  const { colorMode } = useColorMode();

  return (
    <Tooltip
      label={
        isDragging
          ? t('dragAndDrop.copyIntoCell')
          : t('composition.showOptions')
      }
    >
      <Button
        as={Flex}
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        w="28"
        h="20"
        ref={setNodeRef}
        {...droppableZoneStyles(
          isDragging,
          validDropZone,
          isOver,
          colorMode === 'dark',
        )}
        variant="comp-version"
        isLoading={cellsLoading}
        {...(selectedCell && selectedCell[0] === x && selectedCell[1] === y
          ? { bg: 'gray.400', _light: { bg: 'gray.80' } }
          : null)}
        onClick={onCellSelected}
      >
        <Inner
          validDropZone={validDropZone}
          isDragging={isDragging}
          cell={cell}
        />
      </Button>
    </Tooltip>
  );
}

function Inner({
  validDropZone,
  isDragging,
  cell,
}: {
  validDropZone: boolean;
  isDragging: boolean;
  cell: CompositionCell | undefined;
}) {
  const { t } = useTranslation();
  if (validDropZone && isDragging) {
    return (
      <Flex flexDir="column" gap={1} animation={fadeInScaleAnimation()}>
        <Text>
          {t('dragAndDrop.dropHere')}
          <Icons.PackageVariant ml={1} boxSize={defaultIconSize} />
        </Text>
        <Text fontSize="sm" variant="sub">
          {t('composition.optionsCount', {
            count: cell?.optionsCount ?? 0,
          })}
        </Text>
      </Flex>
    );
  }

  return !cell ? (
    <Text variant="sub">{t('general.empty')}</Text>
  ) : (
    <Flex flexDir="column" gap={1}>
      <Text>
        {t('composition.optionsCount', {
          count: cell.optionsCount,
        })}
      </Text>
      <Text fontWeight="normal" variant="sub">
        {t('composition.versionsCount', {
          count: cell.versionsCount,
        })}
      </Text>
    </Flex>
  );
}
