import { useDisclosure } from '@chakra-ui/react';
import { CompositionMaterial } from '@texas/api/endpoints/compositionApi';
import { CreateCompositionMaterial } from '@texas/components/article/variant/compositions/shared/composition/CreateCompositionMaterial';
import { useTranslation } from 'react-i18next';
import { EmptyDataButton } from '../../button/EmptyDataButton';
import { MaterialFormType } from '@texas/components/shared/composition/material/CompositionMaterialForm';

export function AddMaterial({
  compositionId,
  productGroupId,
  subTitle,
  onCreate,
  formType,
}: {
  compositionId: number;
  productGroupId: number | null;
  subTitle?: string;
  formType?: MaterialFormType;
  onCreate: (data: CompositionMaterial) => void;
}) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { t } = useTranslation();
  return (
    <>
      <CreateCompositionMaterial
        formType={formType}
        onCreate={onCreate}
        compositionId={compositionId}
        productGroupId={productGroupId}
        isOpen={isOpen}
        onClose={onClose}
        subTitle={subTitle}
      />
      <EmptyDataButton
        label={t('materials.addMaterial')}
        description={t('materials.addMaterialDesc')}
        onClick={onOpen}
      />
    </>
  );
}
