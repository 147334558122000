import {
  Flex,
  Text,
  Grid,
  GridItem,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  useToast,
} from '@chakra-ui/react';
import {
  Composition,
  compositionApi,
  CompositionMaterial,
  UpdateCompositionMaterialRequest,
} from '@texas/api/endpoints/compositionApi';
import { UpdateCompositionMaterial } from '../../../../../shared/composition/material/UpdateCompositionMaterial';
import { useValueDisclosure } from '@texas/hooks/useValueDisclosure';
import { CompositionForm } from './CompositionForm';
import { fadeInRightAnimation } from '@texas/resources/animations/animations';
import { useTranslation } from 'react-i18next';
import { CompositionMaterialView } from '@texas/components/shared/composition/material/CompositionMaterialView';
import { AddMaterial } from '@texas/components/shared/composition/material/AddMaterial';
import { request } from '@texas/utils/helpers/httpHelpers';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { ServerError } from '@texas/types';

interface CompositionOverviewProps {
  composition: Composition;
  onMaterialChanged: () => void;
  onClose: () => void;
  productGroupId: number | null;
}

export function CompositionOverview({
  composition,
  onMaterialChanged,
  productGroupId,
}: CompositionOverviewProps) {
  const {
    isOpen: editIsOpen,
    value,
    onClose: editOnClose,
    onOpen: editOnOpen,
  } = useValueDisclosure<CompositionMaterial>();
  const { t } = useTranslation();

  const { request: updateRequest } = useApiRequest(
    compositionApi.updateCompositionMaterial,
  );
  const toast = useToast();
  const onSubmit = async (data: UpdateCompositionMaterialRequest) =>
    await request(
      updateRequest,
      [value!.id, data],
      (_) => {
        onMaterialChanged();
        toast({
          title: t('general.updated'),
          status: 'success',
          isClosable: true,
        });
      },
      (error: ServerError) => {
        toast({
          title: t('general.updateFailed'),
          description: error.message,
          status: 'error',
          isClosable: true,
        });
      },
    );

  return (
    <>
      {value && (
        <UpdateCompositionMaterial
          onSubmit={onSubmit}
          compositionMaterialId={value.id}
          productGroupId={productGroupId}
          isOpen={editIsOpen}
          onClose={editOnClose}
        />
      )}
      <Flex flexDir="column" gap={2} animation={fadeInRightAnimation()}>
        <Accordion allowMultiple={true}>
          <AccordionItem borderTop="none">
            <AccordionButton as={Flex} justifyContent="space-between">
              <Text fontWeight="bold">{t('composition.characteristics')}</Text>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel px={0}>
              <CompositionForm composition={composition} />
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem borderBottom="none">
            <AccordionButton as={Flex} justifyContent="space-between">
              <Text fontWeight="bold">
                {t('materials.materialsCounts', {
                  count: composition.materials.length,
                })}
              </Text>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              <Grid
                mt={4}
                rowGap={2}
                gridAutoRows="auto"
                gridAutoColumns="100%"
              >
                {composition.materials.map((x) => {
                  return (
                    <GridItem key={x.id}>
                      <CompositionMaterialView
                        onClick={() => editOnOpen(x)}
                        material={x}
                        onRemoved={() => onMaterialChanged()}
                      />
                    </GridItem>
                  );
                })}
                <GridItem height="full">
                  <AddMaterial
                    compositionId={composition.id}
                    productGroupId={productGroupId}
                    onCreate={() => {
                      onMaterialChanged();
                    }}
                  />
                </GridItem>
              </Grid>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Flex>
    </>
  );
}
