import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const config = createMultiStyleConfigHelpers(tabsAnatomy.keys);

const lineVariant = config.definePartsStyle({
  tablist: {
    color: 'whiteAlpha.600',
    borderBottomColor: 'whiteAlpha.400',
    _light: {
      color: 'gray.300',
      borderBottomColor: 'blackAlpha.200',
    },
  },
  tab: {
    _selected: {
      borderBottomColor: 'texas.sand.100 !important',
      color: 'white',
    },
    _light: {
      color: 'gray.300',
      _selected: {
        color: 'texas.bg.900',
        borderBottomColor: 'texas.burntSienna.500 !important',
      },
    },
  },
});

const stepVariant = config.definePartsStyle({
  tablist: {
    bg: 'gray.800',
    _light: { bg: 'gray.50' },
  },
  tab: {
    width: 'auto',
    height: '2rem',
    ml: '-16px',
    position: 'relative',
    bg: 'gray.800',
    _selected: {
      bg: 'gray.600',
      color: 'white',
      _light: {
        color: 'texas.bg.900',
      },
    },
  },
});

const buttonVariant = config.definePartsStyle({
  tab: {
    fontFamily: 'Barlow',
    fontWeight: 'semibold',
    bg: 'transparent',
    borderRadius: '40px',
    border: '2px solid',
    borderColor: 'whiteAlpha.200',
    color: 'gray.300',
    mr: 2,
    _light: { color: 'gray.500', borderColor: 'blackAlpha.200' },
    _hover: {
      color: 'gray.100',
      borderColor: 'whiteAlpha.400',
      _light: {
        color: 'gray.700',
        borderColor: 'blackAlpha.400',
      },
    },
    _selected: {
      backgroundColor: 'texas.sand.100',
      color: 'white',
      fontWeight: 'bold',
      _light: {
        backgroundColor: 'texas.burntSienna.500',
        color: 'white',
      },
    },
  },
  tabpanel: {
    py: '16px',
    px: 0,
  },
});

const compositionVariant = config.definePartsStyle({
  tab: {
    color: 'gray.200',
    borderRadius: 'md',
    textAlign: 'left',
    border: '1px solid',
    borderColor: 'texas.bg.900',
    _selected: {
      background: 'texas.sand.50',
      color: 'texas.bg.950',
    },
    _light: {
      color: 'gray.400',
      borderColor: 'gray.100',
      _selected: {
        bg: 'texas.burntSienna.500',
        color: 'white',
      },
    },
  },
});

const variants = {
  line: lineVariant,
  step: stepVariant,
  button: buttonVariant,
  composition: compositionVariant,
};

export default config.defineMultiStyleConfig({
  defaultProps: { variant: 'line' },
  variants,
});
