import { Box, Tooltip } from '@chakra-ui/react';
import { stretchAnimation } from '@texas/resources/animations/animations';
import { Icons, defaultIconSize } from './Icons';

export function WarningComponent({
  children,
  warning,
}: {
  children: React.ReactNode;
  warning?: string;
}) {
  return (
    <Box pos="relative">
      {children}
      {warning && (
        <Box pos="absolute" left={-2} top={-3} data-warning="1">
          <Tooltip label={warning}>
            <Icons.AlertCircle
              animation={stretchAnimation()}
              color="yellow.200"
              _light={{
                color: 'yellow.400',
              }}
              boxSize={defaultIconSize}
            />
          </Tooltip>
        </Box>
      )}
    </Box>
  );
}
