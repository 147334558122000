import {
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Tag,
  TagLabel,
  Text,
} from '@chakra-ui/react';
import { FileTag, FileTagType } from '@texas/api/endpoints/filesApi';
import { convertToEnum } from '@texas/utils/helpers/enumHelpers';
import { useTranslation } from 'react-i18next';
import { Icons } from '../Icons';
import { RefObject } from 'react';
import { TFunction } from 'i18next';

export function FileTagPopovers({
  fileTags,
  containerRef,
}: {
  fileTags: FileTag[];
  containerRef: RefObject<HTMLDivElement>;
}) {
  const { t } = useTranslation();
  const garpTags = fileTags.filter((f) => {
    const convertedType = convertToEnum(FileTagType, f.type);
    if (
      convertedType === FileTagType.VariantArtwork ||
      convertedType === FileTagType.VariantPhoto ||
      convertedType === FileTagType.VariantDocument
    ) {
      return true;
    }

    return false;
  });

  const matrixTags = fileTags.filter(
    (f) => convertToEnum(FileTagType, f.type) === FileTagType.CellPhoto,
  );

  const optionTags = fileTags.filter((f) => {
    const convertedType = convertToEnum(FileTagType, f.type);
    if (
      convertedType === FileTagType.CompositionPhoto ||
      convertedType === FileTagType.OptionLayout
    ) {
      return true;
    }

    return false;
  });

  const optionVersions = [
    ...new Set(
      fileTags
        .filter((t) => t.optionVersion !== null)
        .map((t) => t.optionVersion!),
    ),
  ];

  return (
    <Flex gap={1}>
      {garpTags.length > 0 && (
        <FilePopOver
          label={t('variant.overview.garp.title')}
          fileTags={garpTags}
          containerRef={containerRef}
        />
      )}
      {matrixTags.length > 0 && (
        <FilePopOver
          label={t('variant.overview.matrix.title')}
          fileTags={matrixTags}
          containerRef={containerRef}
        />
      )}
      {optionVersions.length >= 3 && (
        <FilePopOver
          label={t('composition.optionNr', { nr: '...' })}
          fileTags={optionTags}
          containerRef={containerRef}
        />
      )}
      {optionVersions.length < 3 &&
        optionVersions.map((v) => (
          <FilePopOver
            key={v}
            label={t('composition.optionNr', { nr: v })}
            fileTags={fileTags.filter((t) => t.optionVersion === v)}
            containerRef={containerRef}
          />
        ))}
    </Flex>
  );
}

function FilePopOver({
  label,
  containerRef,
  fileTags,
}: {
  label: string;
  containerRef: RefObject<HTMLDivElement>;
  fileTags: FileTag[];
}) {
  const { t } = useTranslation();

  return (
    <Popover trigger="hover">
      <PopoverTrigger>
        <Tag
          _light={{ bg: 'white', color: 'gray.100' }}
          bg="gray.900"
          color="gray.600"
          border="1px solid"
        >
          <TagLabel
            color="texas.sand.50"
            _light={{ color: 'texas.burntSienna.500' }}
          >
            {label}
          </TagLabel>
        </Tag>
      </PopoverTrigger>
      <Portal containerRef={containerRef}>
        <PopoverContent
          w="fit-content"
          justifyContent="center"
          bg="gray.900"
          borderColor="gray.600"
          _light={{ bg: 'white', borderColor: 'gray.100' }}
        >
          <PopoverArrow
            bg="gray.900"
            borderColor="gray.600"
            _light={{ bg: 'white!important', borderColor: 'gray.100' }}
          />
          <PopoverBody>
            <Flex gap={2} direction="column">
              {fileTags.map((tag) => (
                <Flex key={tag.id} gap={2}>
                  <Tag variant="colored" w="fit-content" h="fit-content">
                    {getTagLabel(tag.type, t)}
                  </Tag>
                  <Flex w="fit-content" whiteSpace="normal" gap={0.5}>
                    {tag.paths.map((p, i) => (
                      <Flex key={i} alignItems="center" gap={0.5}>
                        {i !== 0 && (
                          <Icons.SlashForward
                            color="gray.400"
                            _light={{ color: 'gray.300' }}
                            boxSize={5}
                          />
                        )}
                        <Text variant="sub">{p}</Text>
                      </Flex>
                    ))}
                  </Flex>
                </Flex>
              ))}
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}

function getTagLabel(type: FileTagType, t: TFunction) {
  const convertedType = convertToEnum(FileTagType, type);

  switch (convertedType) {
    case FileTagType.VariantArtwork:
      return t('variant.garpArtwork');
    case FileTagType.VariantPhoto:
      return t('variant.garpPhoto');
    case FileTagType.VariantDocument:
      return t('variant.garpDocument');
    case FileTagType.CellPhoto:
      return t('variant.cellPhoto');
    case FileTagType.OptionLayout:
      return t('composition.files.optionLayout');
    case FileTagType.CompositionPhoto:
    default:
      return t('composition.files.compositionPhoto');
  }
}
