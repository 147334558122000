// @ngInject
const ArticlesApiService = (
  $q,
  DS,
  ResourcesService,
  DSHttpAdapter,
  toasterService,
) => ({
  getAllByConceptId: (conceptId, options = {}) =>
    ResourcesService.getAll(
      'article',
      Object.assign({}, options, {
        endpoint: `concepts/${conceptId}/articles`,
      }),
    ),

  getAllByCustomerId: (customerId, options = {}) =>
    ResourcesService.getAll(
      'article',
      Object.assign({}, options, {
        params: Object.assign({}, options.params, {
          organizationId: false,
          customerId,
        }),
      }),
    ),

  getById: (articleId, options = {}) =>
    ResourcesService.getById(
      'article',
      articleId,
      Object.assign({}, options, {
        params: Object.assign({}, options.params, { customerId: false }),
      }),
    ),

  newArticle: (article, customerId, options = {}) => {
    if (article.branches) {
      // Make sure we're only sending branch ids
      article.branches = _.map(article.branches, _.partialRight(_.pick, 'id'));
    }

    return ResourcesService.create(
      'article',
      article,
      Object.assign({}, options, {
        params: Object.assign({}, options.params, {
          organizationId: false,
          customerId,
        }),
      }),
    );
  },

  updateArticle: (data, articleId, options = {}) => {
    if (data.branches) {
      // Make sure we're only sending branch ids and blockedFromSync
      data.branches = _.map(
        data.branches,
        _.partialRight(_.pick, 'id', 'blockedFromSync'),
      );
    }

    return ResourcesService.update(
      'article',
      articleId,
      data,
      Object.assign({}, options, {
        params: Object.assign({}, options.params, { customerId: false }),
        updatedFields: [
          'name',
          'itemCode',
          'state',
          'season',
          'archived',
          'ownerId',
          'conceptId',
          'nodeXValues',
          'nodeYValues',
          'mainVariantId',
          'branches',
          'productGroupId',
          'contactId',
        ],
      }),
    );
  },
  getVariantById: (articleId, variantId, options = {}) =>
    ResourcesService.getById(
      'variant',
      variantId,
      Object.assign({}, options, {
        params: Object.assign({}, options.params, { articleId: false }),
      }),
    ).then((variant) => {
      if (variant.articleId === parseInt(articleId, 10)) {
        return variant;
      } else {
        return $q.reject(
          toasterService.error('Option does not belong to article'),
        );
      }
    }),

  getVariantsByArticleId: (articleId, options = {}) =>
    ResourcesService.getAll(
      'variant',
      Object.assign({}, options, {
        params: Object.assign({}, options.params, {
          customerId: false,
          articleId,
        }),
      }),
    ),

  newVariant: (variant, articleId, options = {}) =>
    ResourcesService.create(
      'variant',
      variant,
      Object.assign({}, options, {
        params: Object.assign({}, options.params, {
          customerId: false,
          articleId,
        }),
      }),
    ),

  updateVariant: (data, variantId, options = {}) =>
    ResourcesService.update(
      'variant',
      variantId,
      data,
      Object.assign({}, options, {
        params: Object.assign({}, options.params, { articleId: false }),
        updatedFields: ['name', 'archived', 'description', 'notes'],
      }),
    ),

  deleteVariantFile: (variant, type) =>
    DSHttpAdapter.DEL(
      `${DS.defaults.basePath}variants/${variant.id}/${type}`,
    ).then(
      (response) => {
        variant[`${type}Id`] = 0;
        variant[type] = null;
        return response;
      },
      (error) => $q.reject(ResourcesService.handleError(error)),
    ),

  updateTechnicalInfo: (data, variantId) =>
    DSHttpAdapter.PUT(
      `${DS.defaults.basePath}variants/${variantId}/technical`,
      data,
    ).then(
      (response) => response,
      (error) => $q.reject(ResourcesService.handleError(error)),
    ),

  updatePurchaseInfo: (data, purchaseInfoId) =>
    DSHttpAdapter.PUT(
      `${DS.defaults.basePath}purchaseinformations/${purchaseInfoId}`,
      data,
    ).then(
      (response) => DS.createInstance('variantPurchaseInfo', response.data),
      (error) => $q.reject(ResourcesService.handleError(error)),
    ),

  getSalesInformation: (salesInfoId, options = {}) =>
    ResourcesService.getById(
      'variantSalesInfo',
      salesInfoId,
      Object.assign({}, options, {
        params: Object.assign({}, options.params),
      }),
    ),

  removeCurrency: (salesCurrencyId, options = {}) =>
    ResourcesService.destroy(
      'salesCurrency',
      salesCurrencyId,
      Object.assign({}, options, {
        params: Object.assign({}, options.params, {
          salesInformationId: false,
        }),
      }),
    ),

  newTierPrice: (tierPrice, articleVariantId, options = {}) =>
    ResourcesService.create(
      'tierPrice',
      tierPrice,
      Object.assign({}, options, {
        params: Object.assign({}, options.params, {
          articleId: false,
          articleVariantId,
        }),
      }),
    ),

  getTierPrices: (articleVariantId, options = {}) =>
    ResourcesService.getAll(
      'tierPrice',
      Object.assign({}, options.params, {
        endpoint: `tierprices/variant/${articleVariantId}`,
        articleId: false,
        articleVariantId,
      }),
    ),

  updateTierPrice: (data, tierPriceId, options = {}) => {
    if (_.isArray(data.salesPrices)) {
      _.forEach(data.salesPrices, (salesPrice) => {
        if (Object.prototype.hasOwnProperty.call(salesPrice, 'price')) {
          if (!_.isNumber(salesPrice.price)) {
            salesPrice.price = null;
          } else if (salesPrice.price) {
            delete salesPrice.margin;
          }
        }
      });
    }
    return ResourcesService.update(
      'tierPrice',
      tierPriceId,
      data,
      Object.assign({}, options, {
        params: Object.assign({}, options.params, { articleVariantId: false }),
        updatedFields: [
          'quantity',
          'basePrices',
          'isMain',
          'decimalsInPrice',
          'salesPrices',
        ],
      }),
    ).then((updatedTierPrice) => {
      if (data.isMain) {
        const cachedTiers = _.filter(DS.getAll('tierPrice'), {
          articleVariantId: updatedTierPrice.articleVariantId,
          branchId: updatedTierPrice.branchId,
        });

        _.forEach(cachedTiers, (t) => {
          t.isMain = t.id === tierPriceId;
        });
      }
      return updatedTierPrice;
    });
  },

  deleteTierPrice: (tierPriceId, options = {}) =>
    ResourcesService.destroy(
      'tierPrice',
      tierPriceId,
      Object.assign({}, options, {
        params: Object.assign({}, options.params, { articleVariantId: false }),
      }),
    ),

  getMatrixCellValues: (tierId, sales) =>
    DSHttpAdapter.GET(
      `${DS.defaults.basePath}tierprices/${tierId}/${
        sales ? 'salesvalues' : 'purchasevalues'
      }`,
    ).then(
      (response) => response.data,
      (error) => $q.reject(ResourcesService.handleError(error)),
    ),

  updateMatrixCellValues: (data, tierId, sales) =>
    DSHttpAdapter.PUT(
      `${DS.defaults.basePath}tierprices/${tierId}/${
        sales ? 'salesvalues' : 'purchasevalues'
      }`,
      data,
    ).then(
      (response) => response.data,
      (error) => $q.reject(ResourcesService.handleError(error)),
    ),

  deletePurchaseCellValues: (tierId, supplierId) =>
    DSHttpAdapter.DEL(
      `${DS.defaults.basePath}tierprices/${tierId}/purchasevalues`,
      { params: { supplierId } },
    ).then(
      () => $q.resolve(),
      (error) => $q.reject(ResourcesService.handleError(error)),
    ),

  copyPrices: (targetPurchaseInfoId, sourcePurchaseInfoId) =>
    DSHttpAdapter.PUT(
      `${DS.defaults.basePath}purchaseinformations/${targetPurchaseInfoId}/prices`,
      { sourcePurchaseInfoId },
    ).then(
      () => $q.resolve(),
      (error) => $q.reject(ResourcesService.handleError(error)),
    ),

  getVariantTechnicalFields: (variantId, options = {}) =>
    ResourcesService.getAll(
      'variantTechnicalField',
      Object.assign({}, options, {
        params: Object.assign({}, options.params, {
          articleId: false,
          variantId,
        }),
        cacheResponse: false,
      }),
    ),

  newVariantPurchaseInfo: (purchaseInfo, variantId, options = {}) =>
    ResourcesService.create(
      'variantPurchaseInfo',
      purchaseInfo,
      Object.assign({}, options, {
        endpoint: `variants/${variantId}/purchase`,
        cacheResponse: false,
      }),
    ),

  getVariantPurchaseInfos: (variantId, options = {}) =>
    ResourcesService.getAll(
      'variantPurchaseInfo',
      Object.assign({}, options, {
        endpoint: `purchaseinformations/variant/${variantId}`,
        cacheResponse: false,
      }),
    ),

  getVariantPurchaseInfo: (purchaseInfoId, options = {}) =>
    ResourcesService.getById(
      'variantPurchaseInfo',
      purchaseInfoId,
      Object.assign({}, options, {
        endpoint: 'purchaseinformations',
        params: Object.assign({}, options.params),
      }),
    ),

  getVariantMatrixValues: (variantId) =>
    DSHttpAdapter.GET(
      `${DS.defaults.basePath}variants/${variantId}/matrixvalues`,
    ).then(
      (response) => response.data,
      (error) => $q.reject(ResourcesService.handleError(error)),
    ),

  updateVariantMatrixValue: (variantId, data) =>
    DSHttpAdapter.PUT(
      `${DS.defaults.basePath}variants/${variantId}/matrixvalues`,
      data,
    ).then(
      (response) => response,
      (error) => $q.reject(ResourcesService.handleError(error)),
    ),

  getVariantBranchData: (variantId) =>
    DSHttpAdapter.GET(
      `${DS.defaults.basePath}variants/${variantId}/matrixdata`,
    ).then(
      (response) => response.data,
      (error) => $q.reject(ResourcesService.handleError(error)),
    ),

  updateBranchData: (variantId, data) =>
    DSHttpAdapter.PUT(
      `${DS.defaults.basePath}variants/${variantId}/matrixdata`,
      data,
    ).then(
      (response) => response,
      (error) => $q.reject(ResourcesService.handleError(error)),
    ),

  getMatrixCellHistory: (tierId, sales) =>
    DSHttpAdapter.GET(
      `${DS.defaults.basePath}tierprices/${tierId}/${
        sales ? 'salesmatrixcellhistories' : 'purchasematrixcellhistories'
      }`,
    ).then(
      (response) => response.data,
      (error) => $q.reject(ResourcesService.handleError(error)),
    ),

  getTierPriceHistory: (tierId, sales) =>
    DSHttpAdapter.GET(
      `${DS.defaults.basePath}tierprices/${tierId}/${
        sales ? 'salestierpricehistories' : 'purchasetierpricehistories'
      }`,
    ).then(
      (response) => response.data,
      (error) => $q.reject(ResourcesService.handleError(error)),
    ),

  getPurchaseInfoHistory: (variantId) =>
    DSHttpAdapter.GET(
      `${DS.defaults.basePath}purchaseinformations/${variantId}/purchaseinformationhistories`,
    ).then(
      (response) => response.data,
      (error) => $q.reject(ResourcesService.handleError(error)),
    ),
});

angular
  .module('resources.articles', [])
  .factory('ArticlesApiService', ArticlesApiService);
