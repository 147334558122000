import {
  Box,
  Button,
  Flex,
  IconButton,
  Spinner,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import {
  compositionGroupApi,
  CompositionGroupOverview,
} from '@texas/api/endpoints/compositionGroupApi';
import { useValueDisclosure } from '@texas/hooks/useValueDisclosure';
import { useTranslation } from 'react-i18next';
import { UpdateGroupModal } from '../shared/group/UpdateGroupModal';
import { useActiveContext } from '@texas/hooks/useActiveContext';
import { OptionsContext } from './OptionsOverview';
import { Icons, defaultIconSize } from '@texas/components/shared/Icons';
import { ConfirmCopy } from '../shared/group/ConfirmCopy';
import { useDraggable } from '@dnd-kit/core';
import { FileBrowserModal } from '@texas/components/shared/files/FileBrowserModal';
import { FileLink } from '@texas/api/endpoints/filesApi';
import { useCallback, useState } from 'react';
import { useApiRequest } from '@texas/api/hooks/useApiRequest';
import { request } from '@texas/utils/helpers/httpHelpers';
import { ServerError } from '@texas/types';
import { ErrorsList } from '@texas/components/shared/ErrorsList';
import { DocumentFileModal } from '../shared/file/DocumentFileModal';
import { useFileUploads } from '@texas/api/hooks/useFileUploads';
import { VerifyDialogWithRequest } from '@texas/components/shared/dialog/VerifyDialogWithRequest';
import { FileRejection, useDropzone } from 'react-dropzone';
import { OptionMenu } from './OptionItemMenu';
import { imageExtensions } from '@texas/utils/helpers/filesHelper';
import { notImageValidator } from '@texas/components/shared/dropzone/shared';

export function OptionItem({
  group,
  onClick,
  canCopy,
}: {
  group: CompositionGroupOverview;
  onClick: () => void;
  canCopy: boolean;
}) {
  const { t } = useTranslation();
  const toast = useToast();
  const [fileId, setFileId] = useState<number | undefined>(group.fileId);

  const {
    isOpen: isEditGroupOpen,
    onClose: onEditGroupClose,
    onOpen: onEditGroupOpen,
    value: editGroupValue,
  } = useValueDisclosure<{ id: number; note: string | null }>();

  const { isOpen, onClose, onOpen, value } = useValueDisclosure<{
    version: number;
    id: number;
    title: string;
  }>();

  const {
    isOpen: isFileBrowserOpen,
    onClose: onFileBrowserClose,
    onOpen: onFileBrowserOpen,
  } = useDisclosure();

  const {
    isOpen: isFileModalOpen,
    onClose: onFileModalClose,
    onOpen: onfileModalOpen,
  } = useDisclosure();

  const {
    isOpen: isRemoveFileOpen,
    onClose: onRemoveFileClose,
    onOpen: onRemoveFileOpen,
  } = useDisclosure();

  const { refetchVersions, copyCompositionGroupRequest } =
    useActiveContext(OptionsContext);

  const { request: updateRequest } = useApiRequest(
    compositionGroupApi.updateFile,
  );

  const updateFile = useCallback(
    (file: FileLink) => {
      request(
        updateRequest,
        [group.id, file.id],
        (_) => {
          toast({
            title: t('fileBrowser.updatedFile'),
            status: 'success',
            isClosable: true,
          });
          setFileId(file.id);
        },
        (error: ServerError) => {
          toast({
            title: t('fileBrowser.updateFileFailed'),
            description: <ErrorsList errors={error.errors} />,
            status: 'error',
            isClosable: true,
          });
        },
      );
    },
    [group.id, t, toast, updateRequest],
  );

  const {
    fileUploads,
    isUploading,
    uploadFailed,
    uploadFiles,
    abortFileUpload,
  } = useFileUploads(updateFile);

  function handleDrop(acceptedFiles: File[], fileRejections: FileRejection[]) {
    if (fileRejections.length) {
      toast({
        title: t('fileBrowser.updateFileFailed'),
        description: (
          <ErrorsList
            errors={fileRejections.flatMap((f) =>
              f.errors.flatMap((e) => e.message),
            )}
          />
        ),
        status: 'error',
        isClosable: true,
      });
    }

    if (acceptedFiles.length) {
      uploadFiles(acceptedFiles, group.folderId);
    }
  }
  const { getRootProps, getInputProps, isDragActive, isDragReject, open } =
    useDropzone({
      onDrop: handleDrop,
      useFsAccessApi: false,
      noClick: true,
      multiple: false,
      validator: (file) => notImageValidator(file, t),
    });

  return (
    <>
      <Button
        as={Flex}
        variant="comp-version"
        onClick={onClick}
        alignItems="center"
        role="group"
        gap={2}
      >
        <Flex
          direction="column"
          flexGrow={1}
          position="relative"
          {...getRootProps()}
        >
          {isDragActive && (
            <Flex
              zIndex={1}
              gap={2}
              position="absolute"
              inset={0}
              marginInline="auto"
              bg={isDragReject ? 'red.700' : 'texas.bg.700'}
              _light={{ bg: isDragReject ? 'red.100' : 'white' }}
              justifyContent="center"
              alignItems="center"
            >
              <Icons.FileReplaceOutline />
              <Text>
                {isDragReject
                  ? t('fileBrowser.notValid')
                  : t('fileBrowser.uploadAndReplaceLayout')}
              </Text>
            </Flex>
          )}
          <input multiple={false} {...getInputProps()} />
          <Flex alignItems="center" gap={1}>
            <Text>
              {group.cellX
                ? t('composition.cellOptionNr', { nr: group.version })
                : t('composition.optionNr', { nr: group.version })}
            </Text>
            {isUploading && <Spinner size="sm" ml={1} />}
            {!isUploading && fileId && (
              <Tooltip label={t('composition.files.viewLayout')}>
                <IconButton
                  aria-label={t('composition.files.viewLayout')}
                  icon={<Icons.PencilRuler />}
                  size="sm"
                  variant="ghost"
                  onClick={(e) => {
                    e.stopPropagation();
                    onfileModalOpen();
                  }}
                />
              </Tooltip>
            )}
            {group.compositions.some((c) => c.fileId) && (
              <Tooltip
                label={t('fileBrowser.images', {
                  count: group.compositions.filter((c) => c.fileId).length,
                })}
              >
                <Icons.ImageOutline ml={1} />
              </Tooltip>
            )}

            <Flex ml="auto" gap={2} alignItems="center">
              {canCopy && (
                <Box opacity={0} _groupHover={{ opacity: 1 }}>
                  <Tooltip label={t('dragAndDrop.dragToCopy')}>
                    <Box>
                      <Draggable id={group.id} />
                    </Box>
                  </Tooltip>
                </Box>
              )}

              <OptionMenu
                fileId={fileId}
                onEditGroupOpen={() =>
                  onEditGroupOpen({ id: group.id, note: group.note })
                }
                onOpen={() =>
                  onOpen({
                    version: group.version,
                    id: group.id,
                    title: t('composition.copyIntoNewOption'),
                  })
                }
                openSelectFile={open}
                onFileBrowserOpen={onFileBrowserOpen}
                onRemoveFileOpen={onRemoveFileOpen}
              />
            </Flex>
          </Flex>
          <Text variant="sub" fontSize="sm" whiteSpace="normal">
            {group.note}
          </Text>
        </Flex>
      </Button>
      {value && (
        <ConfirmCopy
          title={value.title}
          isOpen={isOpen}
          onClose={onClose}
          compositionGroupId={value.id}
          onConfirm={(id) => {
            onClose();
            copyCompositionGroupRequest(id, value.id, undefined);
          }}
        />
      )}
      <UpdateGroupModal
        onUpdated={refetchVersions}
        group={editGroupValue}
        onClose={onEditGroupClose}
        isOpen={isEditGroupOpen}
      />
      <FileBrowserModal
        rootFolderId={group.folderId}
        mode="Select"
        isOpen={isFileBrowserOpen}
        onClose={onFileBrowserClose}
        onSelect={(file: FileLink) => {
          updateFile(file);
          onFileBrowserClose();
        }}
        rejectExtensionFilter={imageExtensions}
      />
      <DocumentFileModal
        fileId={fileId}
        onRemoveFileOpen={onRemoveFileOpen}
        onFileBrowserOpen={onFileBrowserOpen}
        onUpload={(files) => uploadFiles(files, group.folderId)}
        isUploading={isUploading}
        uploadFailed={uploadFailed}
        fileUploads={fileUploads}
        abortFileUpload={abortFileUpload}
        isOpen={isFileModalOpen}
        onClose={onFileModalClose}
      />
      <VerifyDialogWithRequest
        headerTitle={t('fileBrowser.removeLayout')}
        secondaryButtonTitle={t('general.cancel')}
        primaryButtonTitle={t('general.confirm')}
        primaryRequest={compositionGroupApi.deleteFile}
        args={[group.id]}
        isOpen={isRemoveFileOpen}
        onClose={onRemoveFileClose}
        onPerformed={() => {
          setFileId(undefined);
        }}
        onSuccessTitle={t('fileBrowser.fileRemoved')}
      >
        {t('alert.areYouSure')}
      </VerifyDialogWithRequest>
    </>
  );
}

function Draggable({ id }: { id: number }) {
  const { attributes, listeners, setNodeRef } = useDraggable({
    id: id,
    data: {
      optionId: id,
    },
  });
  return (
    <IconButton
      icon={<Icons.Drag boxSize={defaultIconSize} />}
      aria-label="drag"
      size="sm"
      variant="ghost"
      cursor="grab"
      ref={setNodeRef}
      {...listeners}
      {...attributes}
    />
  );
}
